import React from "react"
import Layout from "../components/layout"

import { graphql, useStaticQuery } from "gatsby"
import "../styles/main.scss"

const ElindulokPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//elindulok//" } }
        sort: { order: ASC, fields: [frontmatter___id] }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              id
            }
            fields {
              slug
            }
            excerpt(pruneLength: 280)
            html
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <h4 className="text-center pt-6">Süli István</h4>
            <h1 className="text-center pt-3">Elindulok a tér felé</h1>

            <h4 className="text-center pt-3">(Egyszerű történetek)</h4>
            <h5 className="text-center">(Fekete Sas Kiadó; 2018)</h5>
            <hr></hr>
            <div className="container">
              {data.allMarkdownRemark.edges.map(edge => {
                return (
                  <div className="post" key={edge.node.id}>
                    {/* <Link to={edge.node.fields.slug}>
                      <h4 className="pb-4 text-center">
                        {edge.node.frontmatter.title}
                      </h4>
                    </Link> */}
                    <span
                      className=""
                      dangerouslySetInnerHTML={{
                        __html: edge.node.html,
                      }}
                    ></span>
                    <hr></hr>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </Layout>
  )
}

export default ElindulokPage
